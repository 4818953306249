export const capitalizeFirst = (str: string) => str[0].toUpperCase() + str.slice(1)

export const camelToSnake = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()

export const trimEnd = (str: string, character: string) => {
  return str.charAt(str.length - 1) === character ? str.slice(0, -1) : str
}

export const gidToId = (gid: string) => {
  const arr = gid.split('/')
  return arr[arr.length - 1].split('?')[0]
}
